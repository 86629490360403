/**
 * Created by stapletont on 23/06/2015.
 */

define('urlApi', function() {

  var urlApi = {

    // History API
    history: {
      apiCheck: window.history.pushState || false,
      apiUpdateUrl: function(url) {
        history.pushState(null, null, url);
      },
      url: function() {
        return window.location.href;
      },
      variableQuery: '?',
      updateFragment: function(url) {
        urlApi.history.apiUpdateUrl(url);
      },
      dynamicUpdate: function(publish, topic) {
        window.onpopstate = function() {
          publish(topic, 'pop', document.location.href);
        };
      },
      initLoad: function(publish, topic) {
        if (!window.location.hash) {
          return false;
        }
        var uri = window.location.href.split(urlApi.hash.variableQuery);
        publish(topic, uri[0], uri[1]);
        window.location.hash = '';
        return true;
      }
    },

    // Hash API
    hash: {
      apiCheck: typeof window.location.hash,
      apiUpdateUrl: function(url) {
        window.location.hash = '!' + (url.split('!')[1] || '');
      },
      url: function() {
        return window.location.hash;
      },
      variableQuery: '#!',
      updateFragment: function(newHash) {
        if (newHash.indexOf('#!#!') > -1) {
          newHash = newHash.split('#!#')[1];
        }
        if (newHash === '') {
          newHash = '!';
        }
        window.location.hash = newHash;
      },
      dynamicUpdate: function(publish, topic) {
        window.onhashchange = function() {
          var urlHash = window.location.hash;
          if (urlHash) {
            publish(topic, 'hash', urlHash);
          }
        };
      },
      initLoad: function(publish, topic) {
        var url = window.location.href;
        if (urlApi.hash.url().indexOf('!') > -1) {
          return urlApi.hash.url().split('!')[1];
        }

        if (url.indexOf(urlApi.history.variableQuery) > -1) {
          var uri = url.split(urlApi.history.variableQuery);
          publish(topic, uri[0], uri[1]);
        }
        return false;
      }
    }
  };
  return urlApi;
});
